<template>
    <div>
        <div>
            <ExpenseConfirmation_Component></ExpenseConfirmation_Component>
        </div>
    </div>
</template>

<script>
import ExpenseConfirmation_Component from '../../components/YunShu/ExpenseConfirmation_Component.vue';
export default {
    name: "ExpenseConfirmation",
    components: { ExpenseConfirmation_Component }
}
</script>

<style>

</style>