<template>
    <div class="packingBox">
        <div class="topsBox">
            <div class="trailerServer">
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="businessNo" placeholder="请输入业务编号/订单号/提单号/箱号"></el-input>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <el-date-picker v-model="dataVal" type="date" placeholder="做箱日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="pointAddressVal" placeholder="门点地址"></el-input>
                    </div>
                </div>
                <div class="selectItems">
                    <p>港口</p>
                    <div class="ipt">
                        <el-select v-model="trackStatusVal" placeholder="港口">
                            <el-option v-for="item in trackStatusList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <div class="selectItems">
                <span>产品</span>
                <div class="ipt">
                    <el-input v-model="pointAddressVal" placeholder="请选择产品"></el-input>
                </div>
            </div> -->
            </div>
            <div class="trailerServer">
                <!-- <div class="selectItem">
                    <span>港口</span>
                    <div class="ipt">
                        <el-select v-model="portVal" placeholder="请选择港口">
                            <el-option v-for="item in portList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class="selectItem">
                    <span>产品</span>
                    <div class="ipt">
                        <el-select v-model="productVal" placeholder="请选择产品">
                            <el-option v-for="item in productList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->

                <div class="searchBtn" @click="handleSearch">
                    <el-button type="primary" icon="el-icon-search">搜索</el-button>
                </div>
                <div class="resetBtn" @click="handleReset">
                    <span>重置</span>
                </div>

            </div>
        </div>

        <div class="tables">
            <div style="display: flex;">
                <div class="selectItems" v-show="costFlag">
                    <div class="ipt">
                        <el-select v-model="costVal" placeholder="费用确认">
                            <el-option v-for="item in costList" :key="item.status" :label="item.value"
                                :value="item.status">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="selectItems" v-if="costVal == -1">
                    <div class="ipts">
                        <el-input v-model="noteVal" placeholder="备注"></el-input>
                    </div>
                </div>
                <div class="costBtn" v-show="costFlag" @click="handleCost">
                    <span>费用确认</span>
                </div>
            </div>

            <div style="margin-top: 24px;">
                <el-table :data="tableDataList" border :header-row-style="{ height: '50px' }"
                    :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }"
                    style="width: 100%" @row-click="singleElection">
                    <el-table-column fixed label="" width="65">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="templateSelection" :label="scope.$index">&nbsp;</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="freight" label="运费" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.freight }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="barcode_fee" label="条码费" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.barcode_fee }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="other_fee" label="自营其他费用" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.other_fee }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="进港费" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.entrance_fee }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="异提费" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.entrance_fee }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="提空费" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">{{ scope.row.empty_charge_fee }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="小计" width="150">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: #4CAF50;">
                                    {{
                                        scope.row.freight * 1 + scope.row.barcode_fee * 1 + scope.row.other_fee *
                                            1 + scope.row.entrance_fee * 1 + scope.row.entrance_fee *
                                            1 + scope.row.empty_charge_fee * 1
                                    }}.000
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="做箱时间" width="150"></el-table-column> -->
                    <el-table-column prop="confirm" label="费用确认时间" width="200"></el-table-column>
                    <el-table-column prop="bill_no" label="提单号" width="150"></el-table-column>
                    <!-- <el-table-column label="箱型" width="150"></el-table-column> -->
                    <!-- <el-table-column label="箱号" width="150"></el-table-column> -->
                    <el-table-column prop="store_address" label="门点地址" width="150"></el-table-column>
                    <el-table-column prop="dock" label="港区" width="150"></el-table-column>
                    <!-- <el-table-column label="堆场" width="150"></el-table-column> -->
                    <el-table-column prop="create_time" label="下单时间" width="200"></el-table-column>
                </el-table>
            </div>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "ExpenseConfirmation_Component",
    data() {
        return {
            templateSelection: "",
            businessNo: "",//业务编号
            dataVal: "",//日期
            pointAddressVal: "",//门点地址
            trackStatusVal: "",//跟踪状态
            trackStatusList: [{
                value: "宁波港",
                label: "宁波港"
            }, {
                value: "上海港",
                label: "上海港"
            }, {
                value: "南京港",
                label: "南京港"
            }, {
                value: "连云港",
                label: "连云港"
            }, {
                value: "青岛港",
                label: "青岛港"
            }, {
                value: "天津港",
                label: "天津港"
            }, {
                value: "太仓港",
                label: "太仓港"
            }, {
                value: "乍浦港",
                label: "乍浦港"
            }, {
                value: "深圳港",
                label: "深圳港"
            }, {
                value: "蛇口",
                label: "蛇口"
            }, {
                value: "盐田",
                label: "盐田"
            }, {
                value: "张家港",
                label: "张家港"
            }, {
                value: "常熟港",
                label: "常熟港"
            }, {
                value: "芜湖港",
                label: "芜湖港"
            }, {
                value: "合肥港",
                label: "合肥港"
            }, {
                value: "南通港",
                label: "南通港"
            }, {
                value: "广州港",
                label: "广州港"
            }, {
                value: "厦门港",
                label: "厦门港"
            }, {
                value: "江阴港",
                label: "江阴港"
            }, {
                value: "镇江港",
                label: "镇江港"
            }, {
                value: "独山港",
                label: "独山港"
            }, {
                value: "苏州港",
                label: "苏州港"
            }],//跟踪状态数据
            portVal: "",//港口
            portList: "",//港口数据
            productVal: "",//产品
            productList: "",//产品数据
            tableDataList: [],
            paging: {
                total: 0,
                size: 5,
            },
            costVal: "",
            costList: [{
                status: "1",
                value: "确认"
            }, {


                status: "-1",
                value: "驳回"
            }],
            costFlag: false,
            tabIds: "",
            noteVal: ""
        }
    },
    created() {
        this.getFeeList()
    },
    methods: {
        handleSizeChange(val) {  //分页

        },
        getFeeList() {
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerFee/feeList`, {
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result.records);
                this.tableDataList = res.data.result.records
            })
        },
        singleElection(row) {
            // this.templateSelection = this.tableData.indexOf(row);
            // this.templateRadio = row.id;

            if (row.status == '1' || row.status == '-1') {
                this.costFlag = true;
                console.log(row);
                this.tabIds = row.pid
            }
        },
        handleCost() {
            console.log(this.costVal);
            var token = sessionStorage.getItem("token");
            if (!this.costVal) {
                this.$message({
                    showClose: true,
                    message: '请选择确认方式',
                    type: 'error'
                });
                return false
            }
            if (this.costVal == 1) {
                axios.post(`${baseUrl}/trailerFee/feeConfirm`, {
                    ids: this.tabIds,
                    status: 2,
                }, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    if (res.data.success) {
                        console.log(res);
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'success'
                        });
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'success'
                        });
                        return false
                    }
                })
            } else {
                if (this.noteVal == '') {
                    this.$message({
                        showClose: true,
                        message: '请输入驳回原因',
                        type: 'error'
                    });
                    return false
                } else {
                    axios.post(`${baseUrl}/trailerFee/feeConfirm`, {
                        ids: this.tabIds,
                        status: -1,
                        note: this.noteVal,
                    }, {
                        headers: {
                            'authorization': token
                        }
                    }).then(res => {
                        if (res.data.success) {
                            console.log(res);
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'success'
                            });
                        } else {
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'success'
                            });
                            return false
                        }
                    })
                }
            }
        },
        handleSearch() {  //搜索
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerFee/feeList`, {
                params: {
                    keywords: this.businessNo,
                    store_address: this.pointAddressVal,
                    begTime: this.dataVal,
                },
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result.records);
                this.tableDataList = res.data.result.records
            })
        },
        handleReset() { //重置
            this.businessNo = this.dataVal = this.pointAddressVal = this.trackStatusVal = this.portVal = this.productVal = '';
            this.getFeeList()
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

::v-deep .el-table .el-table__cell {
    padding: 0;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

::v-deep .el-table .cell {
    text-align: center;
}

::v-deep .el-table th.el-table__cell>.cell {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.packingBox {
    margin: 0 auto;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;

    .topsBox {
        padding: 24px 0 24px 0;
        background: #FFFFFF;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    }

    .trailerServer {
        padding: 0 24px;
        display: flex;
        margin-top: 16px;

        .selectItem {
            display: flex;
            align-items: center;
            min-width: 240px;
            height: 40px;
            // background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipts {
                width: 240px;
            }

            .el-date-editor {
                width: 100%;
            }

            .ipt {
                margin-left: 16px;
            }
        }

        .selectItem:nth-child(1) {
            margin-left: 0;
        }

        .selectItems {
            display: flex;
            align-items: center;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipt {
                width: 180px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                margin-left: 16px;
            }
        }

        .searchBtn {
            // margin-left: 68px;
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            overflow: hidden;
            cursor: pointer;

            .el-button {
                width: 100%;
                height: 100%;
                background: #FFC13B;
                border-color: transparent;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
                text-align: center;
                // line-height: 40px;
                padding: 0;
                line-height: 40px;
            }
        }

        .resetBtn {
            width: 80px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            border: 1px solid rgba(43, 43, 43, .4);
            margin-left: 32px;
            cursor: pointer;
        }


    }

    .trailerServer:nth-child(1) {
        margin-top: 0;
    }

    .tables {
        padding: 0 24px;
        margin-top: 32px;
        padding-bottom: 32px;

        .costBtn {
            width: 80px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: white;
            // border: 1px solid rgba(43, 43, 43, .4);
            margin-left: 32px;
            cursor: pointer;
            background: #1890FF;
        }

        .paging {
            display: flex;
            justify-content: flex-end;
            position: relative;
            // float: right;
            margin-top: 32px;
            // background-color: #FFFFFF;
        }
    }
}
</style>